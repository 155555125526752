<template>
  <div class="card">
    <div class="card-body">
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
      <div class="row justify-content-md-center">

      <div class="col-12">
        <!--   Confirmação     -->
          <div class="row">
            <!--  Form Content      -->
            <div class="col-12 col-md12">
              <div class="row">
                <!-- Content -->
                <div class="col-12 col-md-12">
                  <!-- Sort Order -->
                  <div class="row">
                    <div class="col-12">
                      <form-confirm v-model="formData" ref="confirmacao" :moedaFrete="moedaFrete">
                        <template slot="title">
                          <h2 class="title">{{ $t('form.proposal.step.confirmacao')}}</h2>
                        </template>
                      </form-confirm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
// Components

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Form
import FormConfirm from './FormConfirm'

// Services
import ProposalService from '@/services/ProposalService'

export default {
  name: 'ProposalList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.proposal') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: true,
      fullPage: true,
      countryList: [],
      equipmentList: [],
      sortOrderList: [
        {
          id: 1,
          nome: 'Preço (Menor Valor)'
        },
        {
          id: 2,
          nome: 'Preço (Maior Valor)'
        }
      ],
      // filterData
      filterData: {
        operation: {
          id: 2,
          nome: 'Importação'
        },
        modality: {
          id: 2,
          nome: 'Marítimo - FCL'
        },
        commoditie: {
          id: null,
          nome: null
        },
        origin: {
          id: null,
          nome: null
        },
        destination: {
          id: null,
          nome: null
        },
        estimatedDate: null,
        equipments: [],
        showShipowner: null,
        sortBy: {
          id: 1,
          nome: 'Preço (Menor Valor)'
        }
      },
      // FormData
      formData: {
        cliente: {
          id: null,
          nome: null,
          cnpjVatnumber: null
        },
        id_tarifario_maritimo: null,
        previsao_embarque: null,
        previsao_desembarque: null,
        valor_frete: null,
        transit_time: null,
        tipo_operacao: null,
        incoterm: null,
        modalidade_pagamento: null,
        modalidade_processo: null,
        rota: null,
        origem: null,
        destino: null,
        equipamentos: [],
        referencia: null,
        referencia_cliente: null,
        carga: {
          mercadoria: null,
          ncm: null,
          descricao: null,
          quantidade: null,
          tipo_volume: null,
          peso_bruto: null,
          segura_naopessoal: false
        },
        exportador: {
          id: null,
          cnpj_vatnumber: null,
          nome: null,
          pais: null,
          estado: null,
          cidade: null,
          ufExterior: null,
          municipioExterior: null,
          cep: null,
          logradouro: null,
          email: null,
          telefone: null,
          contatos: [
            {
              nome: null,
              email: null,
              fone: null
            }
          ]
        },
        importador: {
          id: null,
          cnpj_vatnumber: null,
          nome: null,
          pais: null,
          estado: null,
          cidade: null,
          ufExterior: null,
          municipioExterior: null,
          cep: null,
          logradouro: null,
          email: null,
          telefone: null,
          contatos: [
            {
              nome: null,
              email: null,
              fone: null
            }
          ]
        },
        notify: {
          id: null,
          cnpj_vatnumber: null,
          nome: null,
          pais: null,
          estado: null,
          cidade: null,
          ufExterior: null,
          municipioExterior: null,
          cep: null,
          logradouro: null,
          email: null,
          telefone: null,
          contatos: [
            {
              nome: null,
              email: null,
              fone: null
            }
          ]
        },
        adicionais: {
          seguro: {
            freteMercadoria: false,
            impostos: false,
            outras_despesas: false,
            valor_mercadoria: 0.0,
            valor_frete: 0.0,
            valor_seguro: 0.0
          }
        },
        taxas: [],
        total: []
      },
      tariffOptions: []
    }
  },
  components: {
    FormConfirm,
    Loading
  },
  // Apply filters to local filter
  created () {
    let _this = this
    /** @TODO: Load basilares data */
    _this.isLoading = true

    ProposalService.getProposal(_this.$route.params.id).then((res) => {
      _this.formData = res.data.data

      // Format Numbers
      _this.formData.carga.peso_bruto = this.$util.formatNumber(_this.formData.carga.peso_bruto)
      _this.formData.adicionais.seguro.valor_mercadoria = this.$util.formatNumber(_this.formData.adicionais.seguro.valor_mercadoria)
      _this.formData.adicionais.seguro.valor_seguro = this.$util.formatNumber(_this.formData.adicionais.seguro.valor_seguro)
    }).finally(() => {
      _this.isLoading = false
    })
  },
  computed: {
    moedaFrete () {
      let moeda = ''

      for (let i in this.formData.taxas) {
        let taxa = this.formData.taxas[i]
        if (taxa.tipo === 1) {
          moeda = taxa.moeda
          break
        }
      }

      return moeda
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    }
  },
  methods: {
    onLoading (isLoading) {
      this.isLoading = isLoading
    }
  }
}
</script>

<style scoped>
button:disabled {
  cursor: not-allowed;
}

.sub-extra {
  line-height: 1.5;
}

@media (max-width: 991px) {
  .sumary-extra {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
</style>
